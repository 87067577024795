import { Link } from "react-router-dom";
import Logo from "../../assets/images/selfyouhealthy-logo.svg";
import { Navbar } from "react-bootstrap";

import MobileNavBar from '../MobileNavigation/MobileNavBar';
import { Selected_Menu_Item, setDataInRedux } from "../../action/CommonAction";
import { useDispatch } from "react-redux";
import { Dispatch, useEffect } from "react";


const VerticalMenu = () => {

  useEffect(() => {
    handleTabClick('Dashboard', 'FIRST');
  });

  const dispatch: Dispatch<any> = useDispatch();
  const MenuName = localStorage.getItem("SelectedMenu");




  const handleTabClick = (tabName: any, type?: any) => {
    if (!type && MenuName) {
      localStorage.setItem("SelectedMenu", tabName)
    } else if (!type && !MenuName) {
      localStorage.setItem("SelectedMenu", tabName)
    }
    dispatch(setDataInRedux({ type: Selected_Menu_Item, value: tabName }))
  };

  return (
    <>
      <div id="vertical_menu" className="verticle-menu">
        <Navbar.Brand>
          <div className='logo-bar text-center'>
            <Link to="#"><img src={Logo} width={174} height={24} alt='selfyouhealthy' /></Link>
          </div>
        </Navbar.Brand>
        <div className="menu-list pt-4 px-3 position-relative">
          <Link to={'/dashboard'} className="menu-item" onClick={() => handleTabClick('Dashboard')}>
            <span className="dashboard-icon"></span>
            <span className='nav-text'>Dashboard</span>
          </Link>

          <Link to={'/doctors'} className="menu-item" onClick={() => handleTabClick('Doctors')}>
            <span className="doctors-icon"></span>
            <span className='nav-text'>Doctors</span>
          </Link>

          <Link to={'/patients'} className="menu-item" onClick={() => handleTabClick('Patients')}>
            <span className="patients-icon"></span>
            <span className='nav-text'>Patients</span>
          </Link>

          <Link to={'/appointments'} className="menu-item" onClick={() => handleTabClick('Appointments')}>
            <span className="appointments-icon"></span>
            <span className='nav-text'>Appointments</span>
          </Link>

          <Link to={'/learning-flow'} className="menu-item" onClick={() => handleTabClick('Learning')}>
            <span className="learning-icon"></span>
            <span className='nav-text'>Learning</span>
          </Link>

          <Link to={'/schedule'} className="menu-item" onClick={() => handleTabClick('Schedule')}>
            <span className="schedule-icon"></span>
            <span className='nav-text'>Schedule</span>
          </Link>

          <Link to={'/messages'} className="menu-item" onClick={() => handleTabClick('Messages')}>
            <span className="messages-icon"></span>
            <span className='nav-text'>Messages</span>
          </Link>

          <div className=' bottom-0'> {/* position-fixed */}
            <Link to={'/needhelp'} className="menu-item" onClick={() => handleTabClick('Need Help')}>
              <span className="need-icon"></span>
              <span className='nav-text'>Need Help?</span>
            </Link>

            <Link to={'/settings'} className="menu-item" onClick={() => handleTabClick('Settings')}>
              <span className="settings-icon"></span>
              <span className='nav-text'>Settings</span>
            </Link>
          </div>
        </div>
      </div>

      <div className="d-sm-none d-block">
        <MobileNavBar />
      </div>
    </>
  )
}

export default VerticalMenu;
