import { SET_IS_REFRESH, SET_SIGNUP_TYPE, SET_SIGNUP_DATA, USER_INFO, ADD_PRICE_DATA, 
  UPDATE_REDUX, Selected_Menu_Item ,MeCall_Update,
  LoginFlag} from "../action/CommonAction";


export interface isRefreshState {
  isRefresh: any;
}

interface cartInterface {
  price: any;
  cart: any[];
}

interface forgetPass {
  email: string;
  otp: string
}

export interface reduxState {
  signUpType: string;
  isRefresh: boolean;

  selected_menu_item:any
  meCall_update:any
  // signupData: signupFormInterface;
  // user_info: any;
}

const initialState: reduxState = {
  signUpType: "",
  isRefresh: false,
  /////
    selected_menu_item:{},
    meCall_update:{}

  // signupData: {} as signupFormInterface,
}


interface Action {
  type: string;
  payload?: any;
}

export const commonReducerData = (state: reduxState = initialState, action: Action) => {
  switch (action.type) {
    case UPDATE_REDUX: return { ...action.payload };
    case SET_SIGNUP_TYPE: return { ...initialState, ...{ signUpType: action.payload } };
    case SET_IS_REFRESH: return { ...initialState, ...{ isRefresh: action.payload } };
    case SET_SIGNUP_DATA: return { ...initialState, ...{ signupData: action.payload } };

    ///////////////////////////////////////
    case Selected_Menu_Item:return{...initialState,...{selected_menu_item:action.payload}}
    // case MeCall_Update:return{...initialState,...{meCall_update:action.payload}}

    default: return state;
  }
};

export const updateuserInfo = (state: any = { user_info: {} }, action: Action) => {
  switch (action.type) {
    case USER_INFO: return { user_info: action.payload };
    default: return state;
  }
};


export const updateMeCall = (state: any = { me_call: {} }, action: Action) => {
  switch (action.type) {
    case MeCall_Update: return { me_call: action.payload };
    default: return state;
  }
};

export const loginFlag = (state: any = { loginFlag: {} }, action: Action) => {
  switch (action.type) {
    case LoginFlag: return { loginFlag: action.payload };
    default: return state;
  }
};








