
import React from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import Logout from "../../assets/images/logout.svg";
import WebService from '../../Services/WebService';
import { toast } from 'react-toastify';


interface propsData{
    isShow:any;
    isClose:any;
    handleLogout?:any
}


const LogoutModal = (props:propsData) => {


  const handleLogout = () => {
    WebService.getAPI({
      action: `logout`,
      id:"Logout-btn"
    })
      .then((res: any) => {
        toast.success("Logout Successfully");

        typeof window !== "undefined" && localStorage.clear();

        window.location.href = "/";
      })
      .catch(() => { });
  };

  return (
    <Modal
    show={props.isShow}
    onHide={props.isClose}
    aria-labelledby="contained-modal-title-vcenter"
    centered
    backdrop="static" 

    className="custom-modal-box"
  >
    <Modal.Header closeButton className="p-0"></Modal.Header>
    <Modal.Body className="p-0">
      <Row>
        <Col lg={12} sm={12} className="pt-2 text-center">
            <span>

            </span>
          <img src={Logout} width={90} height={90} alt="Delete" />
          <h4 className="text-dark-blue font-24 font-bold mt-2">
            Are you sure?
          </h4>
          <p>Are you sure you want to Logout ?</p>
          <div>
            <button type="button" className="border-btn" onClick={props.isClose}>
              No
            </button>
            <button
              type="submit"
              className="fill-btn mx-2"
              onClick={handleLogout}
              id="Logout-btn"
            >
              Yes
            </button>
          </div>
        </Col>
      </Row>
    </Modal.Body>
  </Modal>  )
}

export default LogoutModal