import { useEffect, useRef } from "react";

const Video = () => {
    const SetSignature = useRef<any>(null);
    const setMeetingId = useRef<any>(null);

    const joinMeeting = async (config: any) => {
        const { ZoomMtg } = await import("@zoomus/websdk");

        ZoomMtg.setZoomJSLib("https://jssdk.zoomus.cn/3.8.0/lib", "/av");
        ZoomMtg.preLoadWasm();
        ZoomMtg.prepareWebSDK();

        setTimeout(() => {
            ZoomMtg.init({
                leaveUrl: "https://selfyouhealthyweb.winayak.com/appoitment",
                patchJsMedia: true,
                success: () => {
                    console.log("config", config);

                    ZoomMtg.join({
                        meetingNumber: config.meetingNumber,
                        signature: config.signature,
                        passWord: config.password,
                        userName: config.username,
                        sdkKey: config.apiKey,
                        // zak: config.zakToken,
                        success: (s: any) => {
                            console.log("JoinMeeting success", s);
                        },
                        error: (e: any) => {
                            console.error("Error: ZoomMtg.join()", e);
                            if (e.errorCode === 3008) {
                                alert("You are already in another meeting.");
                            } else if (e.errorCode === 403) {
                                // Handle no camera or microphone access
                                alert("No camera or microphone detected. Please check your devices.");
                                // Proceed to join meeting without video
                                ZoomMtg.join({
                                    meetingNumber: config.meetingNumber,
                                    signature: config.signature,
                                    passWord: config.password,
                                    userName: config.username,
                                    sdkKey: config.apiKey,
                                    success: (s: any) => {
                                        console.log("JoinMeeting success without video", s);
                                    },
                                    error: (e: any) => {
                                        console.error("Error: ZoomMtg.join()", e);
                                        if (e.errorCode === 3008) {
                                            alert("You are already in another meeting.");
                                        } else {
                                            alert( e.errorMessage);
                                        }                                    },
                                });
                            } else {
                                alert("An error occurred: " + e.errorMessage);
                            }
                        },
                    });
                },
                error: (e: any) => {
                    console.error("Error: ZoomMtg.init()", e);
                    alert("An error occurred during initialization: " + e.errorMessage);
                },
            });
        }, 1000);
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        SetSignature.current = queryParams.get("signature");
        setMeetingId.current = queryParams.get("meeting_id");

        const config: any = {
            signature: SetSignature.current,
            meetingNumber: setMeetingId.current,
            apiKey: "8ho3aWFITOa5MPiQWnHjzg",
            role: 1,
            password: "",
            username: "Test",
            // zakToken: "your_zak_token_here",
        };

        joinMeeting(config);
    }, []);

    return <></>;
};

export default Video;
