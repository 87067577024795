import { Outlet, useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import Header from "./Header/Header";
import VerticalMenu from "./LeftMenu/VerticalMenu";
import { useState } from "react";
import LearningMenu from "./LeftMenu/LearningMenu";

const Main = () => {
  const location = useLocation();
  const [searchValue, setSearchValue] = useState<string>("");

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };
  const renderMenu = () => {
    if (location.pathname.startsWith("/learning-flow")) {
      return <LearningMenu />;
    }
    return <VerticalMenu />;
  };

  return (
    <>
      <Header onSearch={handleSearch} />
      <div id="main-app" className="layout-veritcle-menu">
        {renderMenu()}
        <div className="app-body kpi-dahboard-page">
          <div className="px-2 pm-0">
            <Container>
              <Outlet context={{ searchValue }} />
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
