import { Routes, Route, Navigate, RoutesProps } from "react-router-dom";

import React, { Suspense } from "react";
import ProtectedRoute from "./ProtectedRoute";
import Main from "../components/Main";
import { useSelector } from "react-redux";
import { RootState } from "../config/Store";
import { UserState } from "../reducer/AuthReducer";
import SelfHealthLoader from "../components/SelfHealthLoader";
import Video from "../pages/Appointments/Video";

interface ProtectedRouteProps extends RoutesProps {
  isAuthenticated: boolean;
  authenticationPath: string;
}

const Navigation = () => {
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    isAuthenticated: localStorage.getItem("access_token") != null,
    authenticationPath: "/login",
  };

  const login: any = useSelector<RootState, UserState>(
    (state: any) => state.userLogin
  );

  const Login = React.lazy(() => import("../components/Login/Login"));
  const Signup = React.lazy(() => import("../components/Login/Signup"));
  const Dashboard = React.lazy(() => import("../pages/Dashboard/Dashboard"));
  const Doctors = React.lazy(() => import("../pages/Doctors/Doctors"));
  const Patients = React.lazy(() => import("../pages/Patients/Patients"));
  const Appointments = React.lazy(
    () => import("../pages/Appointments/Appointments")
  );
  const Learning = React.lazy(() => import("../pages/Learning/Learning"));
  const LearningFlow = React.lazy(
    () => import("../pages/Learning/LearningFlow")
  );
  const Schedule = React.lazy(() => import("../pages/Schedule/Schedule"));
  const Messages = React.lazy(() => import("../pages/Messages/Messages"));
  const Needhelp = React.lazy(() => import("../pages/Needhelp/Needhelp"));
  const Settings = React.lazy(() => import("../pages/Settings/Settings"));

  /**********  Mobile pages ***********/
  const MobileNotifications = React.lazy(
    () => import("../components/MobileNotifications")
  );
  const MobilePropertyFilter = React.lazy(
    () => import("../pages/Doctors/MobileFilterPage")
  );
  const MobileMessageChatBox = React.lazy(
    () => import("../pages/Messages/MobileMessageChatBox")
  );

  const LiveKit = React.lazy(() => import("../pages/Appointments/LiveKit"));

  return (
    <>
      <div id="main-wraper">
        <Routes>
          <Route path="/" element={<Navigate replace to="/login" />} />
          <Route
            path="/login"
            element={
              defaultProtectedRouteProps.isAuthenticated ||
              login.loginSuccess ? (
                <Navigate replace to="/dashboard" />
              ) : (
                <Suspense
                  fallback={
                    <>
                      <SelfHealthLoader show={true} />
                    </>
                  }
                >
                  <Login />
                </Suspense>
              )
            }
          />
          <Route
            path="/signup"
            element={
              defaultProtectedRouteProps.isAuthenticated ||
              login.loginSuccess ? (
                <Navigate replace to="/dashboard" />
              ) : (
                <Suspense
                  fallback={
                    <>
                      <SelfHealthLoader show={true} />
                    </>
                  }
                >
                  <Signup />
                </Suspense>
              )
            }
          />

          <Route
            path="/"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<Main />}
              />
            }
          >
            <Route
              path="/video"
              element={
                <Suspense fallback={<></>}>
                  <Video />{" "}
                </Suspense>
              }
            />
            <Route
              path="/livekit/:id"
              element={
                <Suspense fallback={<></>}>
                  <LiveKit />{" "}
                </Suspense>
              }
            />

            <Route
              path="/dashboard"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Dashboard />{" "}
                </Suspense>
              }
            />
            <Route
              path="/doctors"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Doctors />{" "}
                </Suspense>
              }
            />
            <Route
              path="/patients"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Patients />{" "}
                </Suspense>
              }
            />
            <Route
              path="/appointments"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Appointments />{" "}
                </Suspense>
              }
            />
            <Route
              path="/learning"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Learning />{" "}
                </Suspense>
              }
            />
            <Route
              path="/learning-flow"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <LearningFlow />{" "}
                </Suspense>
              }
            />
            <Route
              path="/schedule"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Schedule />{" "}
                </Suspense>
              }
            />
            <Route
              path="/messages"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Messages />{" "}
                </Suspense>
              }
            />
            <Route
              path="/needhelp"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Needhelp />{" "}
                </Suspense>
              }
            />
            <Route
              path="/settings"
              element={
                <Suspense fallback={<></>}>
                  {" "}
                  <Settings />{" "}
                </Suspense>
              }
            />
            <Route
              path="/notifications"
              element={
                <Suspense fallback={<></>}>
                  <MobileNotifications />{" "}
                </Suspense>
              }
            />
            <Route
              path="/mobile-filter"
              element={
                <Suspense fallback={<></>}>
                  <MobilePropertyFilter />{" "}
                </Suspense>
              }
            />
            <Route
              path="/mobile-message-chat-box"
              element={
                <Suspense fallback={<></>}>
                  <MobileMessageChatBox />{" "}
                </Suspense>
              }
            />
          </Route>

          <Route path="*" element={<Navigate replace to="/login" />} />
        </Routes>
      </div>
    </>
  );
};

export default Navigation;
