import "././phone.scss"
import "./custom.scss";
import React, { useState, Fragment, useEffect } from "react";
import HelperService from "../../Services/HelperService";
// import useClickOutside from "../../../hook/useClickOutside";
import { BiChevronDown } from "react-icons/bi";
import { Form } from "react-bootstrap";
import WebService from "../../Services/WebService";
import useClickOutside from "../../hook/useClickOutside";
import {
   InputGroup
} from "react-bootstrap";

import Call from "../../assets/images/call.svg";
// export interface Options {
//     id: any;
//     code?: string;
//     value: string;
//     value2?: string;
//     parentCode?: string;
//     object?: any;
//     icon?: string;
// }

interface PropData {
    selected: any;
    onChange: any;
    onChangeCode: any;
    value: any;
    isDisable?: boolean;
    onBlur?: any;
    onChangeCountryCode?: any
    placeholder?: any
}

const PhoneNumberInput = (props: PropData) => {
    let textInput = React.createRef<HTMLInputElement>();
    const [options, setOptions] = useState<any>();
    const [selectedOption, setSelectedOption] = useState(props.selected);
    const [isFocus, setIsFocus] = useState(false);
    const [isOpenTop, setIsOpenTop] = useState(false);
    const [mobile, setMobile] = useState(props.value)
    let index = -1;
    const [selectedIndex, setSelectedIndex] = useState(index);
    const [search, setSearch] = useState("");
    const [userInput, setUserInput] = useState("");

    useEffect(() => {
        getCountryOption()
    }, []);

    useEffect(() => {
        setSelectedOption(props.selected ? props.selected : "+91");
    }, [props.selected]);



    useEffect(() => {
        props.value && setMobile(HelperService.getFormattedContact(props.value));
    }, [props.value]);


    let domNode = useClickOutside(() => {
        setIsFocus(false);
    }, this);


    const getCountryOption = () => {
        WebService.getAPI({ action: "country-code" })
            .then((res: any) => {

                const newOptions = res.list.map((item: any) => {
                    item.id = item.phone_code;
                    item.image = `/images/CountryFlagsIcon/${item.image}.svg`


                    return { ...item };
                });
                setOptions(newOptions);
                

            })
            .catch((e: any) => {
                console.error("Error occurred while fetching country codes:", e);
            });
    };


    const checkPossition = () => {
        var topHeight = domNode.current.getBoundingClientRect().y;
        var bottomHeight =
            window.innerHeight - domNode.current.getBoundingClientRect().y;
        if (bottomHeight > 300 || topHeight <= 280) {
            setIsOpenTop(false);
        } else {
            setIsOpenTop(true);
        }
    };

    let searchOption: any[] = search
        ? options?.filter(function (option: any) {
            return option.name.toLowerCase().includes(search.toLowerCase());
        })
        : options;

    let optionsListComponent;

    if (searchOption?.length) {
        optionsListComponent = (
            <ul className={"options " + (isOpenTop ? "open-top" : "")}>
                {searchOption?.map((suggestion: any, index) => {
                    let className;
                    if (index === selectedIndex) {
                        className = "option-active";
                    }

                    return (
                        <li className={className} key={index} onMouseDown={() => onSelect(suggestion)}>
                            <img
                                src={suggestion.image}
                                width={20} alt="" className="me-2" />
                            <strong>{suggestion.name}</strong>
                            <span>{suggestion.phone_code}</span>
                        </li>
                    );
                })}
            </ul>
        );
    } else {
        optionsListComponent = (
            <div className="no-options">
                <em>No data found</em>
            </div>
        );
    };

    const onSelect = (e: any) => {
        setIsFocus(false);
        setSelectedOption(e.id);
        options?.map((value: any, i: number) => {
            if (value.id === e.id) {
                index = i;
                setSelectedOption(value)
                if (props.onChangeCode) {
                    props.onChangeCode(e);
                }
                // if (props.onChangeCountryCode ) {
                //     props.onChangeCountryCode(e.country_code);
                // }
                setSelectedIndex(index);
            }
        });
    };

    const handleKey = (e: any) => {
        if (e.keyCode === 40) { 
            if (selectedIndex < options.length - 1)
                setSelectedIndex(selectedIndex + 1);
        } else if (e.keyCode === 38) {
            if (selectedIndex > 0) setSelectedIndex(selectedIndex - 1);
        } else if (e.keyCode === 13) {
            options?.map((value: any, i: number) => {
                if (selectedIndex === i) {
                    onSelect(value);
                }
            });
        }
    };

    const checkOption = (enterValue: any) => {
        setIsFocus(false);
        var isFound = false;
        options?.map((value: any, i: number) => {
            if (value.id === enterValue || value.value === enterValue) {
                setUserInput(enterValue);
                setSelectedOption(enterValue);
                onSelect(value);
                props.onChange(value);
                isFound = true;
            }
        });

        if (enterValue && !isFound) {
            setUserInput(enterValue);
            setSelectedOption(enterValue);
            var temp: any = { id: enterValue, value: enterValue };
            onSelect(temp);
            props.onChange(temp);
        }

        setSearch("");
    };


    return (
        <>
            <Fragment>
                <div
                    ref={domNode}
                    id={"selectId"}
                    className={"select w-100"}
                >
                    <div className="d-flex">

                        {
                            !isFocus ?
                                <div
                                    style={{ width: "100px", borderTopRightRadius: '0 !important', borderBottomRightRadius: '0 !important' }}
                                    className={"form-style " + (isFocus ? "zindex" : "")}
                                    onKeyDown={(e) => {
                                        !props.isDisable && handleKey(e);
                                    }}
                                    onClick={() => {
                                        !props.isDisable && setIsFocus(!isFocus);
                                    }}
                                    tabIndex={0}
                                    onMouseDown={() => {
                                        if (!isFocus) {
                                            !props.isDisable && checkPossition();
                                        }
                                    }}
                                >
                                    <div className="form-control select-div text-truncate d-flex align-items-center border-end-1 rounded-end-1" style={{}}>
                                        {options?.map((item: any) => {
                                     
                                            
                                            return <>
                                                {
                                                    (selectedOption == item.id) &&
                                                    <>
                                                        <img src={item.image} width={20} className="" alt="1" />
                                                        {/* <span>{item.country_code}</span> */}

                                                        {!isFocus &&

                                                            <svg id="img_downarrow" className="downarrow ms-1" width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3.34025 5.70127C3.30642 5.66831 3.16175 5.54386 3.04275 5.42793C2.29433 4.74828 1.06933 2.97526 0.695417 2.04727C0.635333 1.90634 0.508167 1.55003 0.5 1.35966C0.5 1.17724 0.542 1.00335 0.627167 0.837413C0.746167 0.630561 0.933417 0.464625 1.1545 0.373701C1.30792 0.315169 1.767 0.224245 1.77517 0.224245C2.27742 0.133321 3.0935 0.083313 3.99533 0.083313C4.85458 0.083313 5.63742 0.133321 6.14725 0.207765C6.15542 0.216289 6.72592 0.307213 6.92133 0.406661C7.27833 0.589077 7.5 0.945385 7.5 1.3267V1.35966C7.49125 1.60799 7.26958 2.13024 7.26142 2.13024C6.88692 3.00822 5.722 4.74032 4.94792 5.43645C4.94792 5.43645 4.749 5.63251 4.62475 5.71775C4.44625 5.85073 4.22517 5.91665 4.00408 5.91665C3.75733 5.91665 3.5275 5.8422 3.34025 5.70127Z" fill="#929295" />
                                                            </svg>

                                                        }
                                                        <span>{item.phone_code}</span>
                                                    </>
                                                }
                                            </>
                                        })}

                                    </div>
                                    {isFocus ? optionsListComponent : ""}
                                </div>
                                :
                                < div
                                    className={"form-style " + (isFocus ? "zindex" : "")}
                                    tabIndex={0}
                                >
                                    <input
                                        ref={textInput}
                                        className="form-control text-truncate rounded-end-0 border-end-0 country-code-inpute"
                                        placeholder="search.."
                                        value={isFocus ? search : userInput}
                                        type="text"
                                        // onKeyPress={(e) =>
                                        //     props.max ? HelperService.maxNumber(e, props.max) : ""
                                        // }
                                        onBlur={(e) => { checkOption(e.target.value); props.onBlur && props.onBlur(e) }}
                                        onKeyDown={(e) => {
                                            handleKey(e);
                                        }}
                                        // onClick={() => {
                                        //     setIsFocus(!isFocus);
                                        // }}
                                        onMouseDown={() => {
                                            if (!props.isDisable) {
                                                if (!isFocus) {
                                                    checkPossition();
                                                }
                                                // setIsFocus(true);
                                            }
                                        }}
                                        disabled={props.isDisable}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                    {!isFocus ? (
                                        <div className="col-12 d-flex justify-content-end">
                                            <BiChevronDown
                                                id="img_downarrow"
                                                className="searchdownarrow text-dark"
                                            />
                                        </div>
                                    ) : (
                                        ""
                                    )}

                                    {isFocus ? optionsListComponent : ""}
                                </div>
                        }

                                <div className="input-group " style={{ width:'auto'}}>
                                    <span className="input-group-text text-secondary left-corner" style={{ borderRadius:'0', marginLeft:'-1px',borderRight:"none"}}>
                                    <img src={Call} width={22} height={22} alt="PassKey" /></span>
                                </div>

                        <Form.Control type="text"
                            disabled={props.isDisable}
                            value={mobile}
                            placeholder={props.placeholder ? props.placeholder : "Phone Number"}
                            className="border-start-0 rounded-start-0 ps-0"
                            // onKeyPress={(e) => HelperService.allowOnlyNumericValueCustomInputNumber(e)}
                            // onKeyUp={(e) => HelperService.contactFormatter(e)}
                            onChange={(e: any) => { setMobile(e.target.value); props.onChange(e.target.value && e.target.value.replaceAll("-", "")) }}
                             />
                    </div>
                </div>
            </Fragment >    
        </>
    );
};

export default PhoneNumberInput;