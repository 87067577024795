import loader from "../../src/assets/images/ailoader.gif";

interface PropData {
    show: boolean;
    height?: string;
}

const SelfHealthLoader = (props: PropData) => {
    return (
        <>
            {
                props.show &&
                <div className="selfHealth-loader" style={{ height: props.height ? props.height : "20vh" 
             
             }}>
                    <img src={loader} width={100} height={100} alt='' />
                </div>
            }
        </> 
    )
}

export default SelfHealthLoader;


