import { Link } from "react-router-dom";
import { Row, Col, Form, Tab, Button } from "react-bootstrap";
import "simplebar-react/dist/simplebar.min.css";
import DubleArrow from "../../assets/images/duble-arrow.png";
import Distance from "../../assets/images/distance.svg";
import Health from "../../assets/images/health.svg";
import { Dispatch, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Label } from "../Common/Label/Label";
import WebService from "../../Services/WebService";
import { toast } from "react-toastify";
import SelfHealthSelect from "../SelfHealthSelect/SelfHealthSelect";
import SelfHealthDatePicker from "../SelfHealthDatePicker/SelfHealthDatePicker";
import moment from "moment";
import SelfMultiSelect from "../SelfMultiSelect/SelfMultiSelect";
import HelperService from "../../Services/HelperService";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { MeCall_Update, setDataInRedux } from "../../action/CommonAction";

interface propsData {
  timeZoneListData: any;
  data: any;
}

const DaysDataList = [
  { id: "MONDAY", value: "Monday" },
  { id: "TUESDAY", value: "Tuesday" },
  { id: "WEDNESDAY", value: "Wednesday" },
  { id: "THURSDAY", value: "Thursday" },
  { id: "FRIDAY", value: "Friday" },
  { id: "SATURDAY", value: "Saturday" },
  { id: "SUNDAY", value: "Sunday" },
];

const ClinicDetailsTab = (props: propsData) => {
  const dispatch: Dispatch<any> = useDispatch();

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
    getValues,
    reset,
    register,
    setValue,
  } = useForm<any>({});
  const watchAllFields = watch();
  const [timeZoneList, setTimeZoneList] = useState<any[]>([]);
  const [startTime, setStartTime] = useState<any>();
  const [endTIme, setEndTIme] = useState<any>();
  const { t } = useTranslation();

  useEffect(() => {
    if(props.data?.info.info){

      reset(props.data?.info.info);
    }
    setTimeout(() => {
      if (props.data?.info?.info && props.data?.info?.info?.days) {
        // const daysArray =  props.data.info.info.days.split(',').map((day: string) => ({
        //   id: day.trim(),
        //   value: day.trim()
        // }));
        // setValue("days", props.data.info.info.days);
     
      }
    }, 1000);

    setTimeZoneList([
      ...[{ id: "", value: "Select" }],
      ...props.timeZoneListData,
    ]);

    if (props.data?.info?.info) {
      let fromTIme = props.data?.info?.info?.from_time;
      const formattedFromTime = moment(fromTIme, "HH:mm:ss").format(
        "MMM DD YYYY HH:mm:ss [GMT]ZZ (India Standard Time)"
      );
      setStartTime(fromTIme);
      let toTime = props.data?.info?.info?.to_time;
      const formattedToTime = moment(toTime, "HH:mm:ss").format(
        "MMM DD YYYY HH:mm:ss [GMT]ZZ (India Standard Time)"
      );
      setEndTIme(toTime);
    }
  }, []);

  const onSubmitClinicDetails = (data: any) => {
    const obj = {
      ...data,
      from_time: HelperService.getInverseFormattedDateforScedule (startTime),
      to_time: HelperService.getInverseFormattedDateforScedule(endTIme),
    };
    console.log("obj", obj);

    WebService.postAPI({
      action: `set-medical-profile`,
      body: obj,
      id: "clinic_save",
    })
      .then((res: any) => {
        toast.success(res.message);
        dispatch(
          setDataInRedux({ type: MeCall_Update, value: new Date().getTime() })
        );
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  };



  console.log("dffsf",startTime,endTIme);
  

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitClinicDetails)}>
        <Row>
          <Col ld={6} sm={6}>
            {/* Clinic Name */}
            <div className="form-group mb-3">
              <label>{t("Clinic Name")}</label>
              <Controller
                control={control}
                name="clinic_name"
                rules={{
                  required: t("Please Enter Clinic Name"),
                }}
                render={({
                  field: { onChange, onBlur },
                  fieldState: { isTouched },
                }) => (
                  <>
                    <div className="input-group ">
                      <span className="input-group-text border-end-0 text-secondary left-corner">
                        <img src={Health} width={20} height={20} alt="Health" />
                      </span>
                      <input
                        type="text"
                        className="form-control border-start-0 ps-0"
                        placeholder={t("Clinic Name")}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={watchAllFields.clinic_name}
                      />
                    </div>
                    {(errors["clinic_name"]?.message ||
                      Boolean(errors["clinic_name"]?.message) ||
                      (isTouched && !watchAllFields.clinic_name)) && (
                      <div className="login-error">
                        <Label
                          title={
                            errors.clinic_name?.message ||
                            watchAllFields.clinic_name
                              ? errors.clinic_name?.message?.toString()
                              : t("Please Enter Clinic Name")
                          }
                          modeError={true}
                        />
                      </div>
                    )}
                  </>
                )}
              />
            </div>

            {/* Address Line 1 */}
            <div className="form-group mb-3">
              <label>{t("Address Line")} 1</label>
              <Controller
                control={control}
                name="address_line_1"
                rules={{
                  required: t("Please Enter Address Line 1"),
                }}
                render={({
                  field: { onChange, onBlur },
                  fieldState: { isTouched },
                }) => (
                  <>
                    <div className="input-group ">
                      <span className="input-group-text border-end-0 text-secondary left-corner">
                        <img src={Distance} width={20} height={20} alt="Call" />
                      </span>
                      <input
                        type="text"
                        className="form-control border-start-0 ps-0"
                        placeholder={`${t("Address Line")} 1`}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={watchAllFields.address_line_1}
                      />
                    </div>
                    {(errors["address_line_1"]?.message ||
                      Boolean(errors["address_line_1"]?.message) ||
                      (isTouched && !watchAllFields.address_line_1)) && (
                      <div className="login-error">
                        <Label
                          title={
                            errors.address_line_1?.message ||
                            watchAllFields.address_line_1
                              ? errors.address_line_1?.message?.toString()
                              : t("Please Enter Address Line 1")
                          }
                          modeError={true}
                        />
                      </div>
                    )}
                  </>
                )}
              />
            </div>

            <div className="form-group d-none d-sm-block">
              <label>{t("Address Line")} 2 (Optional)</label>
              <Controller
                control={control}
                name="address_line_2"
                rules={{
                  required: false,
                  // "Please Enter Address Line 2",
                }}
                render={({
                  field: { onChange, onBlur },
                  fieldState: { isTouched },
                }) => (
                  <>
                    <div className="input-group ">
                      <span className="input-group-text border-end-0 text-secondary left-corner">
                        <img src={Distance} width={20} height={20} alt="Call" />
                      </span>
                      <input
                        type="text"
                        className="form-control border-start-0 ps-0"
                        placeholder={`${t("Address Line")} 2`}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={watchAllFields.address_line_2}
                      />
                    </div>
                  </>
                )}
              />
            </div>
          </Col>
          <Col ld={6} sm={6}>
            {/* <div className="font-16 font-bold text-dark-blue pb-2  d-none d-sm-block">
                          Clinic Address
                        </div> */}
            {/* <div className="font-16 font-bold text-dark-blue py-3 d-block d-sm-none">
                          Working Hours
                        </div> */}

            <div className="form-group d-block d-sm-none">
              <label>{t("Time Slot")}</label>
              <div className="input-group mb-3">
                <div className="time-pickers position-relative w-100-mob w-100">
                  <span className="arrow-pos">
                    <img src={DubleArrow} width={10} height={14} alt="Group" />
                  </span>
                  <Form.Select style={{ color: "#0B1956" }}>
                    <option>Slot Time- 30 Mins</option>
                  </Form.Select>
                </div>
              </div>
            </div>

            <div className="form-group mb-3">
              <div className="input-group ">
                <div className="time-pickers position-relative w-100-mob w-100">
                  <Controller
                    control={control}
                    name="days"
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <Form.Group className="">
                        <label> {t("Days")} </label>
                        <SelfMultiSelect
                          selectLimit={100000}
                          options={DaysDataList}
                          // selected={[]}
                          selected={
                            watchAllFields.days &&
                            watchAllFields.days.length > 0 &&
                            typeof watchAllFields.days == "object"
                              ? watchAllFields.days
                              : []
                          }
                          placeholder={"Select days"}
                          onChange={(e: any) => {
                            if (e && e.length) {
                              field.onChange(
                                e.map((item: any) => {
                                  return item.id;
                                })
                              );
                            } else {
                              field.onChange([]);
                            }
                          }}
                        />
                        {errors.days && (
                          <div className="login-error">
                            <Label
                              title={t("Days required")}
                              modeError={true}
                            />
                          </div>
                        )}
                      </Form.Group>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>{t("Time")}</label>
              <div className="d-flex justify-content-between gap-3">
                <div className="input-group ">
                  <div className="time-pickers position-relative w-100-mob w-100 ">
                    <span
                      style={{
                        position: "absolute",
                        zIndex: "1",
                        top: "14px",
                        left: "12px",
                      }}
                    >
                      From:
                    </span>
                    <Controller
                      control={control}
                      name="from_time"
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <Form.Group className="input-space plrspace">
                          <SelfHealthDatePicker
                            placeholderText={t("")}
                            selected={startTime}
                            onChange={(time: any) => {
                              field.onChange(time);
                              setStartTime(time);
                              setEndTIme(null);
                            }}
                            // minTime={startTime?startTime:""}
                            maxTime={HelperService.getFormattedDateforScedule(
                              endTIme
                            )}
                            type="Time"
                          />
                          <span className="arrow-pos">
                            <img
                              src={DubleArrow}
                              width={10}
                              height={14}
                              alt="Group"
                            />
                          </span>
                          {errors.from_time && (
                            <div className="login-error">
                              <Label
                                title={t("From Time required")}
                                modeError={true}
                              />
                            </div>
                          )}

                                            
                        </Form.Group>
                      )}
                    />
                  </div>
                </div>

                <div className="input-group">
                  <div className="time-pickers position-relative w-100-mob w-100">
                    <span
                      style={{
                        position: "absolute",
                        zIndex: "1",
                        top: "14px",
                        left: "12px",
                      }}
                    >
                      To:
                    </span>
                    <Controller
                      control={control}
                      name="to_time"
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <Form.Group className="input-space plrspace">
                          <SelfHealthDatePicker
                            placeholderText={t("")}
                            selected={endTIme}
                            onChange={(time: any) => {
                              field.onChange(time);
                              setEndTIme(time);
                            }}
                            type="Time"
                            minTime={HelperService.getFormattedDateforScedule(
                              startTime
                            )}
                          />
                          <span className="arrow-pos">
                            <img
                              src={DubleArrow}
                              width={10}
                              height={14}
                              alt="Group"
                            />
                          </span>
                          {errors.to_time && (
                            <div className="login-error">
                              <Label
                                title={t("End Time required")}
                                modeError={true}
                              />
                            </div>
                          )}
                        </Form.Group>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group mb-3 mt-3 ">
              <div className="input-group ">
                <div className="time-pickers position-relative w-100-mob w-100">
                  <Controller
                    control={control}
                    name="timezone"
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <Form.Group className="mb-1">
                        <label>{t("Time Zone")}</label>
                        <SelfHealthSelect
                          onChange={(e: any) => {
                            field.onChange(e.id);
                            // setValue("sub_category", "");
                          }}
                          isSearchable={true}
                          options={timeZoneList}
                          selected={watchAllFields.timezone}
                        />
                      </Form.Group>
                    )}
                  />
                  {errors.timezone && (
                    <div className="login-error mt-4">
                      <Label
                        title={t("Please Select Timezone")}
                        modeError={true}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg={6} sm={6} className="mx-auto">
            <div className="text-center pt-3">
              <button
                type="submit"
                className="btn btn-brand-1 w-100 rounded-pill"
                id="clinic_save"
              >
                {t("Save")}
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default ClinicDetailsTab;
