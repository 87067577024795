import React, { Dispatch, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  Container,
  Navbar,
  Dropdown,
  Row,
  Col,
  InputGroup,
  Form,
  Modal,
} from "react-bootstrap";
import "simplebar-react/dist/simplebar.min.css";
import SimpleBar from "simplebar-react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import "../Login/login.scss";
// Images Path
import { FiSearch } from "react-icons/fi";
import NotificationItems from "./NotificationItems";
import avtar from "../../assets/images/user-profile.png";
import NotificationRedicon from "../../assets/images/icon-notification-bell.svg";
import Notificationicon from "../../assets/images/circle_notifications.svg";
import NotificationiconRedMo from "../../assets/images/icon-notification-bell-mo.svg";
import NotificationiconMo from "../../assets/images/icon-notification-bell-mo1.svg";
import Persona from "../../assets/images/persona.svg";
import Policy from "../../assets/images/policy.svg";
import Logout from "../../assets/images/logout1.svg";
import Deleteicon from "../../assets/images/delete-white.svg";
import WebService from "../../Services/WebService";
import HelperService from "../../Services/HelperService";
import UserProfileModal from "../UserProfileModal/UserProfileModal";
import PrivacyPolicyModal from "../PrivacyPolicyModal.tsx/PrivacyPolicyModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../config/Store";
import { reduxState } from "../../reducer/CommonReducer";
import { USER_INFO, setDataInRedux } from "../../action/CommonAction";
import DeleteModal from "../Modals/DeleteModal";
import LogoutModal from "../Modals/LogoutModal";
import NoDataAvailable from "../../components/NoDataAvailable";
import SelfHealthLoader from "../SelfHealthLoader";

interface HeaderProps {
  onSearch: (value: string) => void;
}

const Header = ({ onSearch }: HeaderProps) => {
  const mecallupdate: any = useSelector<RootState, any>(
    (state: any) => state.updateMeCall
  );
  const dispatch: Dispatch<any> = useDispatch();

  const [profilebarShow, setProfilebarShow] = useState(false);
  const [profileShow, setProfileShow] = useState(false);
  const [policyShow, setPolicyShow] = useState(false);
  const [deleteaccShow, setDeleteaccShow] = useState(false);
  const [userProfileData, setUserProfileData] = useState<any>();
  const [notificationListData, setNotificationData] = useState<any>([]);
  const [selectedmenuItem, setSelectedMenuItem] = useState<any>();
  const [notificationIcon, setNotificationIcon] = useState<any>();
  const [isShowNoDoctorAvailable, setIsShowNoDoctorAvailable] =
    useState<any>(false);
  const [keyword, setKeyword] = useState<string>("");
  const [isShowLogoutModal, setShowLogoutModal] = useState<any>(false);
  const [offset, setOffset] = useState(0);
  const [nextOffset, setNextOffset] = useState(0);
  const [notificationLoader, setNotificationLoader] = useState(false);
  const [notificationFlag, setNotificationFlag] = useState(false);

  useEffect(() => {
    userMeCall();
  }, [mecallupdate]);

  useEffect(() => {
    if (offset === 0) {
      setNotificationData([]);
    }
    notificationList();
  }, [offset]);

  const userMeCall = () => {
    WebService.getAPI({ action: "me", id: "login_btn" })
      .then((res: any) => {
        setUserProfileData(res);
        localStorage.setItem("me", res);
        dispatch(setDataInRedux({ type: USER_INFO, value: res }));
      })
      .catch(() => {});
  };

  const notificationList = () => {
    setNotificationLoader(true);
    WebService.getAPI({ action: `notifications?offset=${offset}&limit=6` })
      .then((res: any) => {
        setNotificationData((prev: any) => [...prev, ...res.list]);
        setIsShowNoDoctorAvailable(res.list.length > 0 ? false : true);
        setNotificationIcon(res.unread_count > 0 ? true : false);
        setNextOffset(res.next_offset > 0 ? res.next_offset : 0);
      })
      .catch(() => {})
      .finally(() => {
        setNotificationLoader(false);
      });
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSearch(keyword);
    }
  };

  const handleNotificationRead = (id: any) => {
    let temp = notificationListData.map((item: any) => {
      if (item.id === id) {
        item.is_read = "Y";
      }
      return item;
    });
    const hasUnread = temp.some((message: any) => message.is_read === "N");
    setNotificationIcon(hasUnread);
    setNotificationData(temp);
  };

  return (
    <>
      <header className="site-header">
        <Container fluid className="header-nav">
          <Row className="h-100 align-items-center mobile-search">
            <Col md={7} sm={6} className="ps-0 pm10">
              <h1 className="page-title">{selectedmenuItem}</h1>
            </Col>
            <Col md={5} sm={6}>
              <div className="d-flex flex-wrap justify-content-end iPad-search">
                <div>
                  <InputGroup className="search-box">
                    <InputGroup.Text id="basic-addon1">
                      <FiSearch className="icon" />
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Search..."
                      onChange={(event: any) => {
                        setKeyword(event.target.value);
                      }}
                      onKeyDown={handleKeyPress}
                    />
                  </InputGroup>
                </div>
                <div>
                  <Navbar expand="lg">
                    <Link
                      to="/notifications"
                      className="header-link-btn d-inline-flex d-lg-none notifi-in-mobile"
                    >
                      <img
                        src={
                          notificationIcon
                            ? NotificationiconRedMo
                            : NotificationiconMo
                        }
                        width={45}
                        height={45}
                        alt=""
                        className="d-block d-sm-none"
                        onClick={() => {
                          setOffset(0);
                          setNotificationFlag(!notificationFlag);
                        }}
                      />
                    </Link>
                    <Dropdown className="notification-dd d-none d-sm-block">
                      <Dropdown.Toggle>
                        <img
                          src={
                            notificationIcon
                              ? NotificationRedicon
                              : Notificationicon
                          }
                          width={45}
                          height={45}
                          className="d-none d-sm-block"
                          alt=""
                          onClick={() => {
                            setOffset(0);
                            setNotificationFlag(!notificationFlag);
                          }}
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu align="end">
                        <div className="px-3 pt-2 pb-3 font-semibold border-bottom mb-3 text-lightw-blue">
                          Notifications
                        </div>
                        {notificationLoader ? (
                          <>
                            <SelfHealthLoader
                              show={notificationLoader}
                              height="50vh"
                            />
                          </>
                        ) : (
                          <>
                            <SimpleBar
                              forceVisible="y"
                              autoHide={false}
                              style={{
                                maxHeight: "330px",
                                overflowX: "hidden",
                                paddingRight: "15px",
                              }}
                            >
                              <div
                                className={
                                  nextOffset > 0 ? "px-2 border-bottom" : "px-2"
                                }
                              >
                                {!notificationLoader &&
                                  notificationListData &&
                                  notificationListData.length > 0 &&
                                  notificationListData?.map(
                                    (item: any, index: number) => (
                                      <NotificationItems
                                        item={item}
                                        key={index}
                                        isRead={
                                          item.is_read === "N" ? true : false
                                        }
                                        description={item.message}
                                        moreLink="/"
                                        notiTime={HelperService.getFormattedDatebyText(
                                          item.created_at
                                        )}
                                        parentCallback={(id: any) => {
                                          handleNotificationRead(id);
                                        }}
                                      />
                                    )
                                  )}
                                {isShowNoDoctorAvailable ? (
                                  <NoDataAvailable
                                    isShowMessage={true}
                                    message="No Notifications Found"
                                  />
                                ) : null}
                              </div>
                            </SimpleBar>
                            {nextOffset > 0 && (
                              <div className="px-3 pt-2 text-center">
                                <button
                                  className="border-btn"
                                  onClick={() => setOffset(nextOffset)}
                                >
                                  Load More
                                </button>
                              </div>
                            )}
                          </>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                    <Navbar.Toggle
                      aria-controls="basic-navbar-nav"
                      style={{ display: "none" }}
                    />
                    <div>
                      <div className="d-flex gap-2 profile-in-mobile">
                        <div
                          onClick={() => setProfilebarShow(true)}
                          style={{ cursor: "pointer" }}
                        >
                          {userProfileData?.info.profile_image ? (
                            <img
                              src={userProfileData?.info?.profile_image}
                              width={43}
                              height={43}
                              className="rounded-circle object-fit-cover"
                              alt=""
                              style={{
                                border: "1px solid rgba(15, 94, 221, 0.30)",
                              }}
                            />
                          ) : (
                            <img
                              src={avtar}
                              width={43}
                              height={43}
                              className="rounded-circle object-fit-cover"
                              alt=""
                              style={{
                                border: "1px solid rgba(15, 94, 221, 0.30)",
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </Navbar>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </header>

      {/* Offcanvas Desktop */}
      <Offcanvas
        placement="end"
        className="size-sm mobile-lg-offcanvas"
        show={profilebarShow}
        onHide={() => setProfilebarShow(false)}
        style={{ width: "280px" }}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title style={{ color:'#FFF'}}>Profile Bar</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body style={{ overflowY: "hidden" }}>
          <div className="profile-list">
            <div>
              {userProfileData?.info?.profile_image ? (
                <img
                  src={userProfileData.info.profile_image}
                  width={43}
                  height={43}
                  className="rounded-circle object-fit-cover float-start"
                  alt=""
                  style={{
                    border: "1px solid rgba(15, 94, 221, 0.30)",
                  }}
                />
              ) : (
                <>
                  <img
                    src={avtar}
                    width={43}
                    height={43}
                    className="rounded-circle object-fit-cover float-start"
                    alt=""
                    style={{
                      border: "1px solid rgba(15, 94, 221, 0.30)",
                    }}
                  />
                </>
              )}

              <span className="profile-name">
                {userProfileData?.info?.first_name}&nbsp;
                {userProfileData?.info?.last_name}{" "}
              </span>
            </div>
            <div style={{ clear: "both", overflow: "hidden" }}>&nbsp;</div>
            <div className="pt-4">
              <ul>
                <li>
                  <img src={Persona} alt="Person" width={24} height={24} />
                  <Link to="#" onClick={() => setProfileShow(true)}>
                    Profile
                  </Link>
                </li>
                <li>
                  <img src={Policy} alt="Policy" width={24} height={24} />
                  <Link to="#" onClick={() => setPolicyShow(true)}>
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <img src={Logout} alt="Logout" width={24} height={24} />
                  <Link to="#" onClick={() => setShowLogoutModal(true)}>
                    Logout
                  </Link>
                </li>
              </ul>
            </div>
            <div className="mob-releative position-fixed bottom-0">
              <ul>
                <li style={{ cursor: "pointer" }}>
                  <img src={Deleteicon} alt="" width={24} height={24} />
                  <Link to="#" onClick={() => setDeleteaccShow(true)}>
                    Delete Account
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Offcanvas.Body>
        <div className="right-panel-shape"></div>
      </Offcanvas>

      {/* Profile */}
      <UserProfileModal
        isShow={profileShow}
        isClose={() => setProfileShow(false)}
        userData={userProfileData}
      />

      {/* Privacy Policy Modal */}
      <PrivacyPolicyModal
        isShow={policyShow}
        isClose={() => setPolicyShow(false)}
      />
      {/* Delete Account Modal */}

      <DeleteModal
        isShow={deleteaccShow}
        isClose={() => setDeleteaccShow(false)}
      />

      <LogoutModal
        isShow={isShowLogoutModal}
        isClose={() => setShowLogoutModal(false)}
      />
    </>
  );
};
export default Header;
