import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Row, Col, Form, Modal, Tab, Tabs } from "react-bootstrap";
import "simplebar-react/dist/simplebar.min.css";
import { BsEyeSlash } from "react-icons/bs";
import avtar from "../../assets/images/avtar-male.png";
import BackBtn from "../../assets/images/wback-btn.svg";
import WebService from "../../Services/WebService";
import PersonalDetailsTab from "./PersonalDetailsTab";
import MedicalDetailsTab from "./MedicalDetailsTab";
import ClinicDetailsTab from "./ClinicDetailsTab";
import { useTranslation } from "react-i18next";

interface propsData {
  isShow: any;
  isClose: any;
  userData: any;
}

const UserProfileModal = (props: propsData) => {
  const [password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState<any>(BsEyeSlash);
  const [selectedQualification, setSelectedQualification] = useState<any>([""]);
  const [timeZonedata, setTimeZonedata] = useState<any>([]);
  const { t } = useTranslation();

  useEffect(() => {
    getTimeZoneList();
  }, []);

  const getTimeZoneList = () => {
    WebService.getAPI({
      action: `timezones`,
    })
      .then((res: any) => {
        const newRes = res.list;

        let temp: any[] = [];
        for (let i in newRes) {
          temp.push({ ...newRes[i], value: newRes[i].timezone });
        }
        setTimeZonedata(temp);
      })
      .catch(() => {});
  };

  return (
    <Modal
      show={props.isShow}
      onHide={props.isClose}
      size="lg"
      dialogClassName="modal-65w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="profile-mobile-flw custom-modal-box"
    >
      <Modal.Header closeButton className="p-0">
        <Modal.Title id="example-custom-modal-styling-title">
          <span className="font-20 text-light-blue font-medium d-none d-sm-block">
            {t("Profile")}
          </span>
        </Modal.Title>
      </Modal.Header>
      <div className="d-block d-sm-none">
        <div className="mobile-profile-tabs px-3">
          <div className="d-flex justify-content-between align-items-center h-100">
            <div className="my-auto">
              <div>
                {/* <Dropdown className="profile-dd">
                    <Dropdown.Toggle> */}
                <div className="d-flex gap-2">
                  <div>
                    <img
                      src={
                        props?.userData?.info?.profile_image
                          ? props?.userData?.info?.profile_image
                          : avtar
                      }
                      width={43}
                      height={43}
                      className="rounded-circle object-fit-cover"
                      alt=""
                      style={{
                        border: "1px solid rgba(15, 94, 221, 0.30)",
                      }}
                    />
                  </div>
                </div>
                {/* </Dropdown.Toggle> */}
                {/* <Dropdown.Menu align="end">
                      <Dropdown.Item>My Profile</Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item>Settings</Dropdown.Item>
                          <Dropdown.Divider />
                      <Dropdown.Item>Logout</Dropdown.Item>
                    </Dropdown.Menu> */}
                {/* </Dropdown> */}
              </div>
            </div>
            <div className="my-auto">
              <Link to="#" onClick={props.isClose}>
                <img src={BackBtn} alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Modal.Body
        className="mobile-profile-modal p-0 login-page"
        style={{ background: "#FFF", height: "auto" }}
      >
        <div className="w-100">
          <Row>
            <Col lg={12} sm={12}>
              <Tabs
                defaultActiveKey="personaldetails"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="personaldetails" title={t("Personal Details")}>
                  {timeZonedata && (
                    <PersonalDetailsTab
                      data={props.userData}
                      timeZoneListData={timeZonedata}
                    />
                  )}
                </Tab>
                <Tab eventKey="medicaldetails" title={t("Medical Details")}>
                  <MedicalDetailsTab data={props.userData} />
                </Tab>

                <Tab eventKey="clinicdetails" title={t("Clinic Details")}>
                  {timeZonedata && (
                    <ClinicDetailsTab
                      data={props.userData}
                      timeZoneListData={timeZonedata}
                    />
                  )}
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>

    //   <Modal
    //   show={props.isShow}
    //   onHide={props.isClose}

    //   size="lg"
    //   dialogClassName="modal-90w"
    //   aria-labelledby="contained-modal-title-vcenter"
    //   centered
    //   backdrop="static"

    //   className="profile-mobile-flw custom-modal-box"
    // >
    //   sfs
    // </Modal>
  );
};

export default UserProfileModal;
