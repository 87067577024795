import axios from "axios";
import { toast } from "react-toastify";
import HelperService from "./HelperService";

//Development

// const BASE_URL = "https://selfyouhealthyapi.winayak.com/doctor/";

const BASE_URL = "https://api.selfyouhealthy.com/doctor/";

interface PropData {
  action: string;
  body?: any;
  header?: any;
  isFormData?: boolean;
  isShowError?: boolean;
  id?: string;
  _file?: File;
  key?: string;
  token?: any;
  platform?: any;
}

const WebService = {
  postAPI: function (props: PropData) {
    this.addLoader(props?.id);

    return new Promise((resolve, reject) => {
      var bodyFormData = new URLSearchParams();
      for (let key in props.body) {
        bodyFormData.append(key, props.body[key]);
      }

      const headers = {
        "Content-Type": "application/json",
        Authorization: props.token
          ? "Bearer " + props.token
          : "Bearer " + localStorage.getItem("access_token"),
        appid: localStorage.getItem("app_id"),
        xapikey: localStorage.getItem("p_user_id"),
      };

      axios
        .post(
          `${BASE_URL}${props.action}`,
          props.isFormData ? bodyFormData : props.body,
          {
            headers: headers,
          }
        )
        .then((response) => {
          resolve(response.data);
          this.removeLoader(props?.id);
        })
        .catch((error) => {
          if (error && error.response && error.response.status == 401) {
            this.logout();
          }
          this.removeLoader(props?.id);
          reject(this.errorHandler(error));
          // props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },

  uploadAPI: function (props: PropData) {
    let formData = new FormData();
    if (props._file && props.key) {
      formData.append(props.key, props._file);
    }
    return new Promise((resolve, reject) => {
      for (let key in props.body) {
        formData.append(key, props.body[key]);
      }
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        appid: localStorage.getItem("app_id"),
      };
      axios
        .post(`${BASE_URL}${props.action}`, formData, {
          headers: headers,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error && error.response && error.response.status == 401) {
            this.logout();
          }

          reject(this.errorHandler(error));
          // props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },

  putAPI: function (props: PropData) {
    this.addLoader(props?.id);
    return new Promise((resolve, reject) => {
      var bodyFormData = new URLSearchParams();
      for (let key in props.body) {
        bodyFormData.append(key, props.body[key]);
      }
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        appid: localStorage.getItem("app_id"),
        xapikey: localStorage.getItem("p_user_id"),
      };
      axios
        .put(`${BASE_URL}${props.action}`, props.body, {
          headers: headers,
        })
        .then((response) => {
          resolve(response.data);
          this.removeLoader(props?.id);
        })
        .catch((error) => {
          if (error && error.response && error.response.status == 401) {
            this.logout();
          }
          this.removeLoader(props?.id);

          reject(this.errorHandler(error));
          // props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },

  // getAPI: function <T>(props: PropData) {
  //   let params = new URLSearchParams();
  //   for (let key in props.body) {
  //     params.set(key, props.body[key]);
  //   }
  //   this.addLoader(props?.id);
  //   let url = this.getBaseUrl(props.type);
  //   return new Promise<T>((resolve, reject) => {
  //     axios
  //       .get(`${url}${props.action}`, {
  //         params: params,
  //         headers: this.getHeaders(),
  //       })
  //       .then((response) => {
  //         resolve(response.data);
  //         this.removeLoader(props?.id);
  //       })
  //       .catch((error) => {
  //         if (error && error.response && error.response.status == 401) {
  //           this.clearLocalStorage();
  //           window.location.href = "/";
  //         }
  //         this.removeLoader(props?.id);
  //         reject(this.errorHandler(error));
  //       });
  //   });
  // },

  getAPI: function (props: PropData) {
    let params = new URLSearchParams();
    for (let key in props.body) {
      params.set(key, props.body[key]);
    }
    this.addLoader(props?.id);

    return new Promise((resolve, reject) => {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        // Authorization: "Bearer " + localStorage.getItem("access_token"),
        Authorization: props.token
          ? "Bearer " + props.token
          : "Bearer " + localStorage.getItem("access_token"),
        appid: localStorage.getItem("app_id"),
        lang: props.header ? props.header : "",
        platform: props.platform ? props.platform : "",
      };
      axios
        .get(`${BASE_URL}${props.action}`, {
          params: params,

          headers: headers,
        })

        .then((response) => {
          resolve(response.data);
          this.removeLoader(props?.id);
        })
        .catch((error) => {
          if (error && error.response && error.response.status == 401) {
            this.logout();
          }
          this.removeLoader(props?.id);

          reject(this.errorHandler(error));
          // props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },

  deleteAPI: function (props: PropData) {
    return new Promise((resolve, reject) => {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        appid: localStorage.getItem("app_id"),
      };
      axios
        .delete(`${BASE_URL}${props.action}`, {
          headers: headers,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error && error.response && error.response.status == 401) {
            this.logout();
          }

          reject(this.errorHandler(error));
          // props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },

  errorHandler: function (error: any) {
    if (error?.response) {
      error = error.response;
    }
    var errorMessage;
    if (!error || !error.status) {
      errorMessage = "Server Not Responding";
    } else if (error.status === 401) {
      errorMessage = "Unauthorized";
      this.logout();
    } else if (error.status === 500) {
      errorMessage =
        (error && error.data && error.data.message) ||
        "An unkown exception has occur. Please Contact administrator";
    } else {
      errorMessage = error.data.message;
    }
    toast.error(errorMessage);
    return errorMessage;
  },

  // addLoader(id: any) {
  //   if (id) {
  //     var button = document.getElementById(id) as HTMLButtonElement | null;
  //     if (button != null) {
  //       button.disabled = true;
  //       var loader = document.createElement("span");
  //       loader.className = "loader";
  //       button.prepend(loader);
  //     }
  //   }
  // },

  addLoader(id: any) {
    if (id) {
      var button = document.getElementById(id) as HTMLButtonElement | null;
      if (button != null) {
        button.disabled = true;
        var loader = document.createElement("img");
        loader.src = "/images/loading.gif";
        loader.className = "button-loader";
        button.prepend(loader);
      }
    }
  },

  removeLoader(id: any) {
    if (id) {
      var button = document.getElementById(id) as HTMLButtonElement | null;
      if (button != null) {
        button.disabled = false;
        button.removeChild(button.childNodes[0]);
      }
    }
  },

  logout() {
    localStorage.clear();
    window.location.href = "/login";
  },
};

export default WebService;
