import { Link, useLocation } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import "simplebar-react/dist/simplebar.min.css";
import "../MobileNavigation/mobilenavbar.scss";

// Images Paths
import DashboardIcon from "../../assets/images/dashboard-icon.svg";
import OverviewIcon from "../../assets/images/overview-icon.svg";
import ScheduleIcon from "../../assets/images/schedule-icon.svg";
import MessagesIcon from "../../assets/images/messages-icon.svg";
import SettingsIcon from "../../assets/images/settings-icon.svg";
import DoctorsIcon from "../../assets/images/stethoscope-inact.svg";
import PatientsIcon from "../../assets/images/patient-inact.svg";
import HelpIcon from "../../assets/images/helpcenter-inact.svg";
import LearningIcon from "../../assets/images/learning-inact.svg";
import DashboardActiveIcon from "../../assets/images/dashboard-active-icon.svg";
import OverviewActiveIcon from "../../assets/images/overview-active-icon.svg";
import ScheduleActiveIcon from "../../assets/images/schedule-active-icon.svg";
import MessagesActiveIcon from "../../assets/images/messages-active-icon.svg";
import SettingsActiveIcon from "../../assets/images/settings-active-icon.svg";
import DoctorsActiveIcon from "../../assets/images/left-icon/stethoscope-act.svg";
import PatientsActiveIcon from "../../assets/images/left-icon/patient-act.svg";
import HelpActiveIcon from "../../assets/images/left-icon/helpcenter-act.svg";
import LearningActiveIcon from "../../assets/images/left-icon/learning-act.svg";

const MobileNavBar = () => {
  const location = useLocation();
  return (
    <>
      <div className="mobile-sticky-bottom d-flex justify-content-end gap-3 align-items-center">
        <Navbar bg="white" data-bs-theme="white">
          {/* <Navbar.Brand href="#">loog</Navbar.Brand> */}

          <Nav className="me-auto">
            <Nav.Link href="/dashboard">
              <img
                src={
                  location.pathname.startsWith("/dashboard")
                    ? DashboardActiveIcon
                    : DashboardIcon
                }
                alt=""
                width={24}
                height={24}
              />
              <br />{" "}
              <span
                style={
                  location.pathname.startsWith("/dashboard")
                    ? { color: "#0F5EDD" }
                    : {}
                }
              >
                Dashbaord
              </span>
            </Nav.Link>

            {/* <Nav.Link href="/doctors">
              <img
                src={
                  location.pathname.startsWith("/doctors")
                    ? DoctorsActiveIcon
                    : DoctorsIcon
                }
                alt=""
                width={24}
                height={24}
              />
              <br />{" "}
              <span
                style={
                  location.pathname.startsWith("/doctors")
                    ? { color: "#0F5EDD" }
                    : {}
                }
              >
                Doctors
              </span>
            </Nav.Link>

            <Nav.Link href="/patients">
              <img
                src={
                  location.pathname.startsWith("/patients")
                    ? PatientsActiveIcon
                    : PatientsIcon
                }
                alt=""
                width={24}
                height={24}
              />
              <br />{" "}
              <span
                style={
                  location.pathname.startsWith("/patients")
                    ? { color: "#0F5EDD" }
                    : {}
                }
              >
                Patients
              </span>
            </Nav.Link> */}

            <Nav.Link href="/appointments">
              <img
                src={
                  location.pathname.startsWith("/appointments")
                    ? OverviewActiveIcon
                    : OverviewIcon
                }
                alt=""
                width={24}
                height={24}
              />
              <br />{" "}
              <span
                style={
                  location.pathname.startsWith("/appointments")
                    ? { color: "#0F5EDD" }
                    : {}
                }
              >
                Appointments
              </span>
            </Nav.Link>

            {/* <Nav.Link href="/learning-flow">
              <img
                src={
                  location.pathname.startsWith("/learning-flow")
                    ? HelpActiveIcon
                    : HelpIcon
                }
                alt=""
                width={24}
                height={24}
              />
              <br />{" "}
              <span
                style={
                  location.pathname.startsWith("/learning-flow")
                    ? { color: "#0F5EDD" }
                    : {}
                }
              >
                Learning
              </span>
            </Nav.Link> */}

            <Nav.Link href="/schedule">
              <img
                src={
                  location.pathname.startsWith("/schedule")
                    ? ScheduleActiveIcon
                    : ScheduleIcon
                }
                alt=""
                width={24}
                height={24}
              />
              <br />{" "}
              <span
                style={
                  location.pathname.startsWith("/schedule")
                    ? { color: "#0F5EDD" }
                    : {}
                }
              >
                Schedule
              </span>
            </Nav.Link>

            <Nav.Link href="/messages">
              <img
                src={
                  location.pathname.startsWith("/messages")
                    ? MessagesActiveIcon
                    : MessagesIcon
                }
                alt=""
                width={24}
                height={24}
              />
              <br />{" "}
              <span
                style={
                  location.pathname.startsWith("/messages")
                    ? { color: "#0F5EDD" }
                    : {}
                }
              >
                Messages
              </span>
            </Nav.Link>

            {/* <Nav.Link href="/needhelp">
              <img
                src={
                  location.pathname.startsWith("/needhelp")
                    ? HelpActiveIcon
                    : HelpIcon
                }
                alt=""
                width={24}
                height={24}
              />
              <br />{" "}
              <span
                style={
                  location.pathname.startsWith("/needhelp")
                    ? { color: "#0F5EDD" }
                    : {}
                }
              >
                Need Help?
              </span>
            </Nav.Link> */}

            <Nav.Link href="/settings">
              <img
                src={
                  location.pathname.startsWith("/settings")
                    ? SettingsActiveIcon
                    : SettingsIcon
                }
                alt=""
                width={24}
                height={24}
              />
              <br />{" "}
              <span
                style={
                  location.pathname.startsWith("/settings")
                    ? { color: "#0F5EDD" }
                    : {}
                }
              >
                Settings
              </span>
            </Nav.Link>
          </Nav>
        </Navbar>
      </div>
    </>
  );
};

export default MobileNavBar;
