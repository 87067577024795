import { Link } from "react-router-dom";
import Logo from "../../assets/images/selfyouhealthy-logo.svg";

import MobileNavBar from "../MobileNavigation/MobileNavBar";
import { Selected_Menu_Item, setDataInRedux } from "../../action/CommonAction";
import { useDispatch } from "react-redux";
import { Dispatch, useEffect } from "react";
import { Navbar } from "react-bootstrap";

const LearningMenu = () => {
  useEffect(() => {
    handleTabClick("Dashboard", "FIRST");
  });

  const dispatch: Dispatch<any> = useDispatch();
  const MenuName = localStorage.getItem("SelectedMenu");

  const handleTabClick = (tabName: any, type?: any) => {
    if (!type && MenuName) {
      localStorage.setItem("SelectedMenu", tabName);
    } else if (!type && !MenuName) {
      localStorage.setItem("SelectedMenu", tabName);
    }
    dispatch(setDataInRedux({ type: Selected_Menu_Item, value: tabName }));
  };

  return (
    <>
      <div id="vertical_menu" className="verticle-menu">
        <Navbar.Brand>
          <div className="logo-bar text-center">
            <Link to="#">
              <img src={Logo} width={174} height={24} alt="selfyouhealthy" />
            </Link>
          </div>
        </Navbar.Brand>
        <div className="menu-list pt-4 px-3 position-relative">
          <Link to={"#"} className="menu-item">
            <span className="chapter-icon"></span>
            <span className="nav-text">Chapter 1</span>
          </Link>

          <Link to={"#"} className="menu-item">
            <span className="chapter-icon"></span>
            <span className="nav-text">Chapter 2</span>
          </Link>

          <Link to={"#"} className="menu-item">
            <span className="quiz-icon"></span>
            <span className="nav-text">Quiz</span>
          </Link>

          <Link to={"#"} className="menu-item">
            <span className="chapter-icon"></span>
            <span className="nav-text">Chapter 3</span>
          </Link>

          <Link to={"#"} className="menu-item">
            <span className="chapter-icon"></span>
            <span className="nav-text">Chapter 4</span>
          </Link>

          <Link to={"#"} className="menu-item">
            <span className="quiz-icon"></span>
            <span className="nav-text">Quiz</span>
          </Link>

          <Link to={"#"} className="menu-item">
            <span className="certificate-icon"></span>
            <span className="nav-text">Certificate</span>
          </Link>

          <Link to={"#"} className="menu-item" style={{  textDecoration: "underline" }}
              onClick={() => window.history.back()}>
            <span className="nav-text">Go Back</span>
          </Link>

          <div className="bottom-0">
            <Link
              to={"/needhelp"}
              className="menu-item"
              onClick={() => handleTabClick("Need Help")}
            >
              <span className="need-icon"></span>
              <span className="nav-text">Need Help?</span>
            </Link>

            <Link
              to={"/settings"}
              className="menu-item"
              onClick={() => handleTabClick("Settings")}
            >
              <span className="settings-icon"></span>
              <span className="nav-text">Settings</span>
            </Link>
          </div>
        </div>
      </div>

      <div className="d-sm-none d-block">
        <MobileNavBar />
      </div>
    </>
  );
};

export default LearningMenu;
