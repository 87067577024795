import iconCalendarPlus from "../../assets/images/icon-calendar-plus.svg";
import { MdCircle } from "react-icons/md";
import { Link } from "react-router-dom";
import WebService from "../../Services/WebService";

interface propsData {
  isRead: boolean;
  description: string;
  notiTime: any;
  moreLink: any;
  item: any;
  parentCallback: any;
  key: any;
}

const NotificationItems = (props: propsData) => {
  const setIsRead = (item: any) => {
    if (props.isRead) {
      WebService.putAPI({ action: "notification/" + item.id })
        .then((res: any) => {
          props.parentCallback(item.id);
        })
        .catch(() => {});
    }
  };

  return (
    <>
      <div className="px-2" onClick={() => setIsRead(props.item)}>
        <div className="notification-item">
          <div>
            <div className="read-icon">
              <img src={iconCalendarPlus} sizes="15" alt="" />
              {props.isRead == true ? (
                <MdCircle size={8} className="icon-unread" />
              ) : null}
            </div>
          </div>
          <div>
            <p className="mb-1">
              {props.description} <Link to={props.moreLink}></Link>
            </p>
            <p className="mb-0 text-secondary">{props.notiTime}</p>
          </div>
        </div>
      </div>
    </>
  );
};
export default NotificationItems;
