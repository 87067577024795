import { Dispatch, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Form, Button, Dropdown } from "react-bootstrap";
import "simplebar-react/dist/simplebar.min.css";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import Call from "../../assets/images/call.svg";
import Person from "../../assets/images/person.svg";
import ProfilePhoto from "../../assets/images/user-profile.png";
import { Controller, useForm } from "react-hook-form";
import { Label } from "../Common/Label/Label";
import HelperService from "../../Services/HelperService";
import WebService from "../../Services/WebService";
import { toast } from "react-toastify";
import SelfHealthSelect from "../SelfHealthSelect/SelfHealthSelect";
import {
  LoginFlag,
  MeCall_Update,
  setDataInRedux,
} from "../../action/CommonAction";
import { useDispatch } from "react-redux";
import DubleArrow from "../../assets/images/duble-arrow.png";
import PhoneNumberInput from "../PhoneNumberInput/PhoneNumberInput";
import { TagsInput } from "react-tag-input-component";
import { useTranslation } from "react-i18next";
import { ChevronDown, CircleFill } from "react-bootstrap-icons";
import awayDot from "../../assets/images/away-dot.png";
import busyDot from "../../assets/images/busy-dot.png";
import availableDot from "../../assets/images/available-dot.png";
import offline from "../../assets/images/offline-dot.png";

interface propsData {
  data: any;
  timeZoneListData: any;
}

const PersonalDetailsTab = (props: propsData) => {
  const dispatch: Dispatch<any> = useDispatch();
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
    getValues,
    reset,
    register,
    setValue,
  } = useForm<any>({});
  const watchAllFields = watch();

  const [password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState<any>(BsEyeSlash);
  const [selectedLanguages, setSelectedLanguages] = useState<any>([]);
  const [timeZoneList, setTimeZoneList] = useState<any[]>([]);
  const [languageList, setLanguageList] = useState<any>([]);
  const [profileImage, setProfileImage] = useState<any>();

  const [profileImageName, setProfileImageName] = useState<any>();

  useEffect(() => {
    reset(props.data?.info);
    setProfileImageName(props.data?.info.profile_image);
    // setTimeZoneList(props?.timeZoneListData);
    if(props?.timeZoneListData){
      setTimeZoneList([
        ...[{ id: "", value: "Select" }],
        ...props?.timeZoneListData,
      ]);
    }
 

    // const QualificationArr: any = {props.data?.info?.language? props.data?.info?.language?.split(","):""};
    const QualificationArr: any = props.data?.info?.language
      ? props.data.info?.language.split(",")
      : [];

    setSelectedLanguages(QualificationArr);

    getLanguageList();
  }, []);

  const getLanguageList = () => {
    WebService.getAPI({
      action: `languages`,
    })
      .then((res: any) => {
        setLanguageList(res.list);
      })
      .catch(() => {});
  };

  const handleToggle = () => {
    if (type === "password") {
      setIcon(BsEye);
      setType("text");
    } else {
      setIcon(BsEyeSlash);
      setType("password");
    }
  };

  const onSubmitDetails = (data: any) => {
    const languageString = selectedLanguages?.join(", ");
    const newdata = {
      ...data,
      language: languageString ? languageString : "",
    };
    WebService.putAPI({
      action: `set-profile`,
      body: newdata,
      id: "save_btn",
    })
      .then((res: any) => {
        toast.success(res.message);
        dispatch(
          setDataInRedux({ type: MeCall_Update, value: new Date().getTime() })
        );
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  };

  const handlevalidateImage = (e: any) => {
    const { files } = e.target;
    if (files && files[0]) {
      var sizeInBytes = files[0].size;
      var sizeInKb = sizeInBytes / 1024;
      var sizeInMb = sizeInKb / 1024;
      if (sizeInMb > 2) {
        toast.warn("Image must be less than 2 MB!", { theme: "colored" });
        return;
      }
      var isImageExtensions = /(\.(jpg|jpeg|png))$/i;
      if (!isImageExtensions.test(files[0].name)) {
        toast.warn("Invalid File Type", { theme: "colored" });
        return;
      }
      const blob = URL.createObjectURL(files[0]);
      // setFileName(blob);
      setProfileImage(files[0]);
      setProfileImageName(blob);
      handleUploadImage(files[0]);
      // onUploadSigntaure(files[0]);
    }
  };

  const handleUploadImage = (data: any) => {
    WebService.uploadAPI({
      action: `upload-image`,
      body: { image: data },
    })
      .then((res: any) => {
        toast.success(res.message);
      })
      .catch(() => {});
  };

  const handleRemoveImage = (data: any) => {
    WebService.deleteAPI({
      action: `delete-image`,
    })
      .then(() => {
        setProfileImageName(null);
      })
      .catch(() => {});
  };

  const statusData: any = [
    { icons: availableDot, id: "AVAILABLE", value: "Available" },
    { icons: awayDot, id: "AWAY", value: "Away" },
    { icons: busyDot, id: "BUSY", value: "Busy" },
    { icons: offline, id: "OFFLINE", value: "Offline" },
  ];

  return (
   
    <>
      <form onSubmit={handleSubmit(onSubmitDetails)}>
        <Row>
          <Col lg={6} sm={6}>
            {/* firstName */}
            <div className="form-group mb-3">
              <label>{t("First Name")}</label>
              <Controller
                control={control}
                name="first_name"
                rules={{
                  required: t("Please Enter Name"),
                }}
                render={({
                  field: { onChange, onBlur },
                  fieldState: { isTouched },
                }) => (
                  <>
                    <div className="input-group ">
                      <span className="input-group-text border-end-0 text-secondary left-corner">
                        <img src={Person} width={20} height={20} alt="Person" />
                      </span>
                      <input
                        type="text"
                        className="form-control border-start-0"
                        placeholder={t("First Name")}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={watchAllFields.first_name}
                      />
                    </div>
                    {(errors["first_name"]?.message ||
                      Boolean(errors["first_name"]?.message) ||
                      (isTouched && !watchAllFields.first_name)) && (
                      <div className="login-error">
                        <Label
                          title={
                            errors.first_name?.message ||
                            watchAllFields.first_name
                              ? errors.first_name?.message?.toString()
                              : t("Please Enter Name")
                          }
                          modeError={true}
                        />
                      </div>
                    )}
                  </>
                )}
              />
            </div>

            <div className="form-group mb-3">
              <label>{t("Last Name")}</label>
              <Controller
                control={control}
                name="last_name"
                rules={{
                  required: t("Please Enter Last Name"),
                }}
                render={({
                  field: { onChange, onBlur },
                  fieldState: { isTouched },
                }) => (
                  <>
                    <div className="input-group ">
                      <span className="input-group-text border-end-0 text-secondary left-corner">
                        {/* <HiOutlineEnvelope size={16} /> */}
                        <img src={Person} width={20} height={20} alt="Person" />
                      </span>
                      <input
                        type="text"
                        className="form-control border-start-0"
                        placeholder={t("Last Name")}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={watchAllFields.last_name}
                      />
                    </div>
                    {(errors["last_name"]?.message ||
                      Boolean(errors["last_name"]?.message) ||
                      (isTouched && !watchAllFields.last_name)) && (
                      <div className="login-error">
                        <Label
                          title={
                            errors.last_name?.message ||
                            watchAllFields.last_name
                              ? errors.last_name?.message?.toString()
                              : t("Please Enter Last Name")
                          }
                          modeError={true}
                        />
                      </div>
                    )}
                  </>
                )}
              />
            </div>

            <div className="form-group mb-3">
              <label>{t("Phone Number")}</label>
              <Controller
                control={control}
                name="mobile_number"
                rules={{
                  required: false,
                }}
                render={({
                  field: { onChange, onBlur },
                  fieldState: { isTouched },
                }) => (
                  <>
                    <div className="input-group">
                      <PhoneNumberInput
                        selected={watchAllFields?.dial_code}
                        value={watchAllFields?.mobile_number}
                        isDisable={true}
                        onChange={""}
                        onChangeCode={""}
                      />
                    </div>
                  </>
                )}
              />
            </div>

            {/* Time Zone */}
            {/* <div className="form-group mb-3 ">
              <div className="input-group ">
                <div className="time-pickers position-relative w-100-mob w-100">
                  <Controller
                    control={control}
                    name="timezone"
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <Form.Group className="mb-1">
                        <label>{t("Time Zone")}</label>
                        <SelfHealthSelect
                          onChange={(e: any) => {
                            field.onChange(e.id);
                            // setValue("sub_category", "");
                          }}
                          isSearchable={true}
                          options={timeZoneList}
                          selected={watchAllFields.timezone}
                        />
                      </Form.Group>
                    )}
                  />

                  {errors.timezone && (
                    <div className="login-error mt-4">
                      <Label
                        title={t("Please Select Timezone")}
                        modeError={true}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div> */}

            <div className="form-group mb-3 mt-4">
              <div className="form-group mb-2 mt-2 position-relative tags-inputs  w-100-mob w-100">
                <label>{t("Language")} </label>
                <Controller
                  control={control}
                  name="language"
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <div className="form-control" style={{ height: "auto" }}>
                      <TagsInput
                        value={selectedLanguages}
                        onChange={(val: any) => {
                          field.onChange(val);
                          setSelectedLanguages(val);
                        }}
                        name="language"
                        placeHolder={t("Enter language")}
                      />
                    </div>
                  )}
                />
                {errors.language && (
                  <div className="login-error mt-1">
                    <Label title={t("Select Language")} modeError={true} />
                  </div>
                )}
              </div>
            </div>

            <div className="form-group mb-3">
              <label>{t("Years of Experience")} </label>
              <div className="input-group ">
                <input
                  type="number"
                  min={1}
                  className="form-control ps-2 p-2"
                  placeholder={t("Years of Experience")}
                  // value={""}
                  onKeyPress={HelperService.allowOnlyNumericValueUpto2}
                  {...register("year_of_experience", { required: true })}
                />
              </div>
              {errors.year_of_experience && (
                <div className="login-error">
                  <Label
                    title={t("Number of Experience is Required")}
                    modeError={true}
                  />
                </div>
              )}
            </div>

            <div className="form-group  mb-4">
              <label>{t("Number of Surgeries")}</label>
              <div className="input-group ">
                <input
                  type="number"
                  min={1}
                  className="form-control ps-2 p-2"
                  placeholder={t("Number of Surgeries")}
                  // value={""}
                  onKeyPress={HelperService.allowOnlyNumericValueUpto4}
                  {...register("no_of_surgeries", { required: true })}
                />
              </div>
              {errors.no_of_surgeries && (
                <div className="login-error ">
                  <Label
                    title={t("Number of Surgeries is Required")}
                    modeError={true}
                  />
                </div>
              )}
            </div>
          </Col>

          <Col lg={6} sm={6}>
            <div className="form-group mb-3">
              <label>{t("Profile Photo")}</label>
              <div className="d-flex justifiy-content-between align-items-center">
                <div>
                  <img
                    src={profileImageName ? profileImageName : ProfilePhoto}
                    alt=""
                    width="140"
                    height="140"
                    className="rounded-circle"
                    style={{
                      border: "5px solid rgba(15, 94, 221, 0.20)",
                    }}
                  />
                </div>
                <div className="text-center ps-4 my-auto">
                  <button
                    type="button"
                    style={{ cursor: "pointer" }}
                    className="upload-img border-0"
                  >
                    <label
                      htmlFor="file-input"
                      style={{ color: "#FFF", cursor: "pointer" }}
                    >
                      {t("Upload an Image")}
                      <input
                        id="file-input"
                        type="file"
                        onChange={handlevalidateImage}
                        style={{ display: "none" }}
                      />
                    </label>
                  </button>

                  <br />

                  {profileImageName ? (
                    <Link
                      to="#"
                      className="remove-photo"
                      onClick={handleRemoveImage}
                    >
                      {t("Remove Photo")}
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="form-group ">
              <div className="input-group mb-3">
                <div className="time-pickers position-relative w-100-mob w-100 availability-section">
                  <Controller
                    control={control}
                    name="activity_status"
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <Form.Group className="">
                        <Form.Label>{t("Status")} </Form.Label>
                        {/* <span className="text-danger">*</span> */}
                        {/* 
                        <Form.Select
                          style={{ color: "black" }}
                          onChange={(e: any) => {
                            field.onChange(e.target.value);
                          }}
                          className=""
                        >
                          <option value="AWAY">
                            <img src={awayDot} />
                            {t("Away")}
                          </option>
                          <option value="BUSY">
                            {t("Busy")}
                          </option>
                          <option value="OFFLINE">
                            {t("Offline")}
                          </option>
                        </Form.Select> */}

                        <SelfHealthSelect
                          options={statusData}
                          selected={watchAllFields.activity_status}
                          onChange={(e: any) => {
                            field.onChange(e.id);
                            // setValue("sub_category", "");
                          }}
                          isHideArrow={true}
                        />

                        <span
                          className="right-icon"
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "55%",
                          }}
                        >
                          <ChevronDown
                            id="img_downarrow"
                            className="downarrow"
                          />
                        </span>
                        {errors.activity_status && (
                          <div className="login-error ">
                            <Label
                              title={t("Is Status required")}
                              modeError={true}
                            />
                          </div>
                        )}
                      </Form.Group>
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <label>{t("About You")}</label>
              <div className="input-group mb-2">
                <Controller
                  control={control}
                  name="about_us"
                  rules={{ required: t("About You is required") }}
                  render={({ field }) => (
                    <textarea
                      {...field}
                      id="about_us"
                      name="about_us"
                      className={`form-control p-2 ${
                        errors.about_us ? "is-invalid" : ""
                      }`}
                      rows={5}
                      cols={5}
                      style={{ height: "120px" }}
                    />
                  )}
                />
              </div>
              {errors.about_us && (
                <div className="login-error mb-3">
                  <Label title={t("About You is required")} modeError={true} />
                </div>
              )}
            </div>

            <div className="pt-4">
              <div className="font-16 font-bold text-dark-blue pb-2">
                {t("Registration & Subscription")}
              </div>
              <div className="font-12 font-regular text-gery pb-1">
                {t("Registered On")}{" "}
                {HelperService.getFormattedDatebyText(props.data.created_at)}
              </div>
              {/* <div className="font-12 font-regular text-gery">
                Subscription- Active ( 15th April 2025 )
              </div> */}
            </div>
          </Col>
        </Row>
        <Row className="mt-0">
          <Col lg={6} sm={6} className="mx-auto">
            {/* <div className="text-center pt-3"> */}
            <button
              type="submit"
              id="save_btn"
              className=" btn-brand-1 w-100 rounded-pill"
            >
              {t("Save")}
            </button>
            {/* </div> */}
          </Col>
        </Row>
      </form>
    </>
  );
};

export default PersonalDetailsTab;
