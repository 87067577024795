import moment from "moment";
import { ObjectType } from "typescript";
import WebService from "./WebService";
import { toast } from "react-toastify";
const HelperService = {
  getFormatedDateForDetail(dt: any, format?: any) {
    var stillUtc = moment.utc(dt).toDate();
    if (dt) {
      if (format) {
        return moment(stillUtc).local().format(format);
      } else {
        return moment(stillUtc)
          .local()
          .format(
            localStorage.getItem("date_format") + " hh:mm A" ??
              "DD-MM-YYYY  hh:mm A"
          );
      }
    }
  },

  getFormattedContact: function (e: any) {
    if (e) {
      const match = e.toString().replace(/\D+/g, "");
      const part1 = match.length > 2 ? `${match.substring(0, 3)}` : match;
      const part2 = match.length > 3 ? `-${match.substring(3, 6)}` : "";
      const part3 = match.length > 6 ? `-${match.substring(6, 9)}` : "";
      const part4 = match.length > 9 ? `-${match.substring(9, 13)}` : "";
      const part5 = match.length > 13 ? `-${match.substring(13, 17)}` : "";

      var x = part1 + "" + part2 + "" + part3 + "" + part4 + "" + part5;
      return x;
    } else {
      return e;
    }
  },

  allowOnlyNumericValueCustomInputNumber(e: any) {
    var numbers = /^[0-9]$/;
    if (!e.key.match(numbers) && e.keyCode != 6) {
      e.preventDefault();
      return false;
    }

    if (e.currentTarget.value.length > 17) {
      e.preventDefault();
      return false;
    }
  },

  allowOnlyNumericValue(e: any) {
    var numbers = /^[0-9]$/;
    if (!e.key.match(numbers) && e.keyCode != 8) {
      e.preventDefault();
      return false;
    }

    if (e.currentTarget.value.length > 9) {
      e.preventDefault();
      return false;
    }
  },

  allowOnlyNumericValueUpto2(e: any) {
    var numbers = /^[0-9]$/;
    if (!e.key.match(numbers) && e.keyCode != 8) {
      e.preventDefault();
      return false;
    }

    if (e.currentTarget.value.length > 1) {
      e.preventDefault();
      return false;
    }
  },
  allowOnlyNumericValueUpto4(e: any) {
    var numbers = /^[0-9]$/;
    if (!e.key.match(numbers) && e.keyCode != 8) {
      e.preventDefault();
      return false;
    }

    if (e.currentTarget.value.length > 5) {
      e.preventDefault();
      return false;
    }
  },

  contactFormatter: function (e: any) {
    if (e.currentTarget.value) {
      e.currentTarget.value = this.getFormattedContact(
        e.currentTarget.value ? e.currentTarget.value.replaceAll("-", "") : ""
      );
    }
  },

  maxNumber(e: any, maxNumber: number) {
    if (maxNumber && e.currentTarget.value.length > maxNumber) {
      e.preventDefault();
      return false;
    }
  },

  getFormatedDateWithYear(d: any) {
    return d ? moment.utc(d).format("YYYY-MM-DD") : "";
  },

  getFormatedDateWithYearWithOutUtc(d: any) {
    return d ? moment(d).format("YYYY-MM-DD") : "";
  },

  getFormatedDateWithYearTime(d: any) {
    return d ? moment(d).format("YYYY-MM-DD hh:mm:ss") : "";
  },
  getFormattedDatebyText(dt: any) {
    return moment.utc(dt).format("DD MMMM YYYY");
  },

  getFormattedDateTimebyText(dt: any) {
    return moment.utc(dt).format("DD MMMM YYYY HH:mm a");
  },

  getFormattedDatebyMonthYear(dt: any) {
    return moment.utc(dt).format("MMMM YYYY ");
  },

  getFormattedDatebyMonth(dt: any) {
    return moment.utc(dt).format("MM");
  },
  getFormattedDatebyYear(dt: any) {
    return moment.utc(dt).format("YYYY");
  },

  getFormattedTimetoAP(dt: any) {
    return moment(dt, "HH:mm:ss").format("hh:mm A");
  },

  getFormatedTimeWithSecond(d: any) {
    return d ? moment.utc(d).format("hh:mm:ss") : "";
  },

  getFormatedTimeWithSecond2(d: any) {
    return d ? moment.utc(d).format("HH:mm:ss") : "";
  },

  getFormatedTimeWithTime(d: any) {
    return d ? moment.utc(d).format("HH:mm a") : "";
  },

  getFormatedTimeWithoutUtc(d: any) {
    return d ? moment(d).format("hh:mm a") : "";
  },

  getInverseFormattedDateforScedule(d: any) {
    try {
      // Use moment to parse the input as an ISO 8601 string and format it to "HH:mm:ss"
      const parsedTime = moment(d, moment.ISO_8601, true);

      // Check if the parsed time is valid
      if (parsedTime.isValid()) {
        return parsedTime.format("HH:mm:ss");
      } else {
        return d; // If it's not valid, return the original input
      }
    } catch (e) {
      // Fallback in case of any other error
      return d;
    }
  },
  // getInverseFormattedDateforScedule(d: any) {
  //   try {
  // const date = new Date(d);

  //   // Extract hours, minutes, and seconds
  //   const hours = ('0' + date.getHours()).slice(-2); // Ensure two digits for hours
  //   const minutes = ('0' + date.getMinutes()).slice(-2); // Ensure two digits for minutes
  //   const seconds = ('0' + date.getSeconds()).slice(-2); // Ensure two digits for seconds

  //   // Return formatted time string
  //   return `${hours}:${minutes}:${seconds}`;
  // } catch {
  //   return d;
  // }
  // },

  getFormattedDateforScedule(dt: any) {
    try {
      const [hours, minutes, seconds] = dt.split(":").map(Number);

      // Get the current date
      const currentDate = new Date();

      // Set the time part based on the provided time string
      currentDate.setHours(hours, minutes + 1, seconds, 0);

      // Return the date string in the desired format
      return currentDate.toString();
    } catch {
      if (dt) {
        let date: any = moment(dt).add(1, "minutes");
        return new Date(date);
      }
      return "";
    }
  },

  getInitialsOfName(d: any) {
    return d ? d.charAt(0).toUpperCase() : "";
  },

  formateDecimal(value: any) {
    return value ? parseFloat(value).toFixed(2) : "";
  },

  formateUptoTwoDecimal(e: any) {
    if (e && e.target) {
      e.currentTarget.value = this.formateDecimal(e.target.value);
    }
  },

  removeDecimal(value: any) {
    return value ? Math.ceil(value) : value;
  },

  ///
};

export default HelperService;
