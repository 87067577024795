import "./SelfHealthDatePicker.scss";
import React, { useState, Fragment, useEffect } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsCalendarDate } from "react-icons/bs";
import HelperService from "../../Services/HelperService";

interface PropData {
  onChange?: any;
  selected?: any;
  type?: string;
  minData?: any;
  maxData?: any;
  minTime?: any;
  maxTime?: any;
  placeholderText?: any;
  isDisabled?: boolean;
  isReset?: boolean;
  timeInterval?: any;
}

const SelfHealthDatePicker = (props: PropData) => {
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [fromtime, setFromTime] = useState<any>();

  useEffect(() => {
    if (props.selected) {
      setStartDate(new Date(props.selected) as any);
    } else {
      setStartDate(null);
    }
  }, [props.selected]);

  useEffect(() => {
    if (props.selected) {
      setFromTime(props.selected as any);
    } else {
      setFromTime(null);
    }
  }, [props.selected]);

  useEffect(() => {
    if (props.selected) {
      if (props.type === "Time") {
        const formattedTime = moment(props.selected, "HH:mm:ss").toDate();
        setFromTime(formattedTime);
      } else {
        setStartDate(new Date(props.selected) as any);
      }
    } else {
      setStartDate(null);
      setFromTime(null);
    }
  }, [props.selected, props.type]);

  const [selectedTime, setSelectedTime] = useState(props.selected);

  useEffect(() => {
    setSelectedTime(props.selected);
  }, [props.selected]);

  useEffect(() => {
    setStartDate(null);
    setEndDate(null);
  }, [props.isReset]);

  return (
    <>
      <Fragment>
        <div className="rounded-pill date-pick">
          {!props.type && (
            <DatePicker
              className="form-control"
              selected={startDate}
              peekNextMonth
              dateFormat="dd-MMMM-yy"
              dropdownMode="select"
              showMonthDropdown
              showYearDropdown
              disabled={props.isDisabled}
              minDate={props.minData ? props.minData : ""}
              maxDate={props.maxData ? props.maxData : ""}
              onChange={(date: any) => {
                setStartDate(date);
                if (props.onChange) {
                  if (date) {
                    props.onChange(moment(date).format("YYYY-MM-DD"));
                  } else {
                    props.onChange(null); // Send null if date is cleared
                  }
                }
              }}
              placeholderText={
                props.placeholderText ? props.placeholderText : "dd/mm/yy"
              }
            />
          )}
          {props.type == "inline" && (
            <DatePicker
              className="form-control"
              selected={startDate ? startDate : ""}
              peekNextMonth
              dateFormat="MM/dd/yy"
              dropdownMode="select"
              inline
              showMonthDropdown
              showYearDropdown
              disabled={props.isDisabled}
              minDate={props.minData ? props.minData : ""}
              maxDate={props.maxData ? props.maxData : ""}
              // onChange={(date: any) => {
              //   setStartDate(date);
              //   if (props.onChange && date) {
              //     props.onChange(moment(date).format("YYYY-MM-DD"));
              //   }
              // }}
              onChange={(date: any) => {
                setStartDate(date);
                if (props.onChange) {
                  if (date) {
                    props.onChange(moment(date).format("YYYY-MM-DD"));
                  } else {
                    props.onChange(null); // Send null if date is cleared
                  }
                }
              }}
              placeholderText={
                props.placeholderText ? props.placeholderText : "mm/dd/yy"
              }
            />
          )}

          {/* {props.type == "Time" && (
            <DatePicker
              className="form-control"
              selected={startDate}
              showTimeSelectOnly
              disabled={props.isDisabled}
              showTimeSelect
              timeFormat="hh:mm aa"
              timeIntervals={30}
              timeCaption="Time"
              dateFormat="hh:mm aa"
              maxTime={props.maxTime ? props.maxTime : ""}
              minTime={props.minTime ? props.minTime : ""}
              onChange={(date: any) => {
                setStartDate(date);
                if (props.onChange) {
                  props.onChange(date);
                }
              }}
              placeholderText={
                props.placeholderText ? props.placeholderText : ""
              }
            />
          )} */}

          {props.type == "Time" && (
            <DatePicker
              className="form-control"
              selected={fromtime}
              showTimeSelectOnly
              showTimeSelect
              /////forlist
              // timeFormat="h:mm aa"
              timeFormat="HH:mm"
              timeIntervals={props.timeInterval}
              timeCaption="Time"
              disabled={props.isDisabled}
              // for showing
              // dateFormat="MM/dd/yy hh:mm aa"
              minTime={props.minTime || new Date().setHours(0, 0, 0, 0)}
              maxTime={props.maxTime || new Date().setHours(23, 59, 59, 999)}
              // dateFormat="h:mm aa"
              dateFormat="HH:mm"
              onChange={(date: any) => {
                // const formattedTime = moment(date).format("HH:mm:ss");
                setFromTime(date);
                if (props.onChange) {
                  props.onChange(date);
                }
              }}
              placeholderText={
                props.placeholderText ? props.placeholderText : ""
              }
            />
          )}

          {props.type == "ValidationTime" && (
            <DatePicker
              className="form-control"
              selected={selectedTime}
              showTimeSelectOnly
              disabled={props.isDisabled}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={props.timeInterval || 15}
              timeCaption="Time"
              minTime={props.minTime || new Date().setHours(0, 0, 0, 0)}
              maxTime={props.maxTime || new Date().setHours(23, 59, 59, 999)}
              dateFormat="HH:mm"
              onChange={(date) => {
                setSelectedTime(date);
                if (props.onChange) {
                  props.onChange(date);
                }
              }}
              placeholderText={props.placeholderText || ""}
            />
          )}

          {props.type == "range" && (
            <DatePicker
              className="form-control"
              selected={startDate ? startDate : ""}
              peekNextMonth
              dateFormat="MM/dd/yy"
              dropdownMode="select"
              inline
              showMonthDropdown
              showYearDropdown
              disabled={props.isDisabled}
              minDate={props.minData ? props.minData : ""}
              maxDate={props.maxData ? props.maxData : ""}
              minTime={props.minTime || new Date().setHours(0, 0, 0, 0)}
              maxTime={props.maxTime || new Date().setHours(23, 59, 59, 999)}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              onChange={(date: any) => {
                const [start, end] = date;
                setStartDate(start);
                setEndDate(end);
                if (props.onChange) {
                  props.onChange(date);
                }
              }}
            />
          )}

          {props.type == "DATETIME" && (
            <DatePicker
              className="form-control"
              selected={startDate}
              disabled={props.isDisabled}
              showTimeSelect
              timeFormat="hh:mm aa"
              timeIntervals={30}
              timeCaption="Time"
              dateFormat="MM/dd/yy hh:mm aa"
              onChange={(date: any) => {
                setStartDate(date);
                if (props.onChange) {
                  props.onChange(date);
                }
              }}
              placeholderText={
                props.placeholderText ? props.placeholderText : ""
              }
            />
          )}

          {props.type == "MONTH" && (
            <DatePicker
              className="form-control"
              selected={startDate}
              dateFormat="MMMM/YYYY"
              showMonthYearPicker
              inline
              disabled={props.isDisabled}
              onChange={(date: any) => {
                setStartDate(date);
                if (props.onChange) {
                  props.onChange(date);
                }
              }}
              placeholderText={
                props.placeholderText ? props.placeholderText : ""
              }
            />
          )}

          {props.type == "YEAR" && (
            <DatePicker
              className="form-control"
              selected={startDate}
              showYearPicker
              dateFormat="yyyy"
              yearItemNumber={9}
              onChange={(date: any) => {
                setStartDate(date);
                if (props.onChange) {
                  props.onChange(moment(date).format("YYYY"));
                }
              }}
              placeholderText={
                props.placeholderText ? props.placeholderText : ""
              }
            />
          )}
        </div>
      </Fragment>
    </>
  );
};

SelfHealthDatePicker.defaultProps = {
  placeholder: "Select",
  selected: "",
  isSearchable: false,
  key: new Date().getTime(),
};

export default SelfHealthDatePicker;
