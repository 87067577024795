import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import SimpleBar from 'simplebar-react'
import WebService from '../../Services/WebService'

interface propsData{
    isShow:any,
    isClose:any
}

const PrivacyPolicyModal = (props:propsData) => {
const [privacyPolicyData,setPrivacyPolicyData] = useState<any>()


  useEffect(()=>{
    getPrivacyPolicyData()
  },[])


const getPrivacyPolicyData =()=>{
  WebService.getAPI({
    action:`privacy-policy?lang=en`
  }).then((res:any)=>{
setPrivacyPolicyData(res)

  })
  .catch(()=>{

  })
}

  return (
    <Modal
    show={props.isShow}
    onHide={props.isClose}
    size="lg"
    dialogClassName="modal-90w"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    backdrop="static" 

    className="custom-modal-box"
  >
    <Modal.Header closeButton className="p-0">
      <Modal.Title id="example-custom-modal-styling-title">
        <span className="font-20 text-light-blue font-medium">
          Privacy Policy
        </span>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="p-0">
      <Row>
        <Col lg={12} sm={12} className="pt-2">
          {/* <div className="font-16 font-medium text-light-blue pb-3">
            Privacy Policy
          </div> */}
          {/* <SimpleBar
            forceVisible="y"
            autoHide={false}
            style={{
              maxHeight: "400px",
              overflowX: "hidden",
              paddingRight: "15px",
            }}
          >
            <h5 className="font-15 font-lighter text-light-blue">1. Introduction</h5>
            <p className="font-14 text-dark-blue font-regular">
              Welcome to MedicBox. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application and related services.
            </p>
            <h5 className="font-15 font-lighter text-light-blue">2. Information We Collect</h5>
            <p className="font-14 text-dark-blue font-regular">
              a. Personal Information: - Name - Email address - Date of birth - Gender - Health-related information entered by the user (e.g., medical history, symptoms, medication)
            </p>
            <p className="font-14 text-dark-blue font-regular">
              b. Non-Personal Information: - Device information (e.g., device type, operating system) - Usage data (e.g., app interactions, session duration)
            </p>
            <h5 className="font-15 font-lighter text-light-blue">3. How We Collect Information</h5>
            <p className="font-14 text-dark-blue font-regular">
              We may collect information through:
              <ul style={{ paddingLeft: '15px' }} className="mt-2">
                <li>User input within the app</li>
                <li>Automated technologies such as cookies and analytics tools</li>
                <li>Third-party integrations (e.g., wearable devices)</li>
              </ul>
            </p>
            <h5 className="font-15 font-lighter text-light-blue">4. Use of Information</h5>
            <p className="font-14 text-dark-blue font-regular">
              We use the collected information for:
              <ul style={{ paddingLeft: '15px' }} className="mt-2">
                <li>Providing personalized health-related content and features</li>
                <li>Improving our services</li>
                <li>Sending updates and notifications</li>
                <li>Responding to user inquiries and support requests</li>
              </ul>
            </p>
            <h5 className="font-15 font-lighter text-light-blue">5. Sharing of Information</h5>
            <p className="font-14 text-dark-blue font-regular">
              We may share information with:
              <ul style={{ paddingLeft: '15px' }} className="mt-2">
                <li>Service providers (e.g., hosting, analytics)</li>
                <li>Third-party integrations chosen by the user</li>
                <li>Legal authorities if required by law</li>
              </ul>
            </p>
            <h5 className="font-15 font-lighter text-light-blue">6. Security</h5>
            <p className="font-14 text-dark-blue font-regular mb-0">We implement industry-standard security measures to protect your information. </p>

          </SimpleBar> */}
          <SimpleBar
            forceVisible="y"
            autoHide={false}
            style={{
              maxHeight: "400px",
              overflowX: "hidden",
              paddingRight: "15px",
            }}
          >
            <div className='mt-4'>
          <div dangerouslySetInnerHTML={{ __html: privacyPolicyData }} />
          </div>
          </SimpleBar>
        </Col>
      </Row>
    </Modal.Body>
  </Modal>
  )
}

export default PrivacyPolicyModal